import React from "react";

import "./Navigation.css";
import logo from "../../img/logo.png";

const Navigation = () => {
    return (
        <div className="navbar">
            <div className="logo">
                <a href="#home">
                <img src={logo} className="logo"/>
                Guardefy
                </a>
            </div>
            <div>
                <ul className="links">
                    <li>
                        <a href="#SecurityAudits">Security Audits</a>
                    </li>
                    <li>
                        <a href="#Company">About Us</a>
                    </li>
                    <li>
                        <a href="#ContactUs">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navigation;
