import React from "react";
import "./About.css";

const About = () => {
    return (
        <div className="container_about" id="Company">
            <div className="title">
                About<div className="brand">&nbsp;Guardefy</div>
            </div>
            <div className="container_cards">
                <div className="card">
                    <div className="card_header">Since 2019</div>
                    <div className="card_body">making security audits</div>
                </div>
                <div className="card">
                    <div className="card_header">50+</div>
                    <div className="card_body">made audits</div>
                </div>
                <div className="card">
                    <div className="card_header">100+</div>
                    <div className="card_body">found vulnerabilities</div>
                </div>
            </div>
        </div>
    );
};

export default About;
