import React from "react";

import './Footer.css';

const Footer = () => {
     return (
        <div className="footer_container">
            <div className="footer_item">© 2023 Guardefy</div>
            <div className="footer_item"><a href="PrivacyPolicy.html" target="_blank">Privacy Policy</a></div>
            <div className="footer_item"><a href="TermsOfService.html" target="_blank">Terms of Service</a></div>
        </div>
     );
};

export default Footer;